export const environment = {
	COMPANY_NAME: 'evollo',
	APP_NAME: 'ID',
	production: true,
	apiUrl: 'https://tokiomarine-account.api.evollo.cloud',
	qualityApiUrl: 'https://tokiomarine-quality.api.evollo.cloud',
	recaptchaId: '6Le_4tQmAAAAALwMgkZF8dyNpcwY9KG1-a8_se6m',
	webClientId: '4jpbnapvd8q90onkvrsqmlvij9',
	apps: {
		id: 'https://tokiomarine.id.evollo.com.br',
		admin: 'https://tokiomarine.admin.evollo.com.br',
		quality: 'https://tokiomarine.quality.evollo.com.br',
		cognito: "tokiomarine-id.auth.us-east-1.amazoncognito.com"
	},
	features: {
		enableSignUp: false,
		enableAzureSignUp: false,
		recaptchaEnabled: true,
		enableSupport: false,
		enableFaq: false
	},
};
